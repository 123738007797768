<template>
  <div>
    <div class="generic-filter row m-0">
      <!-- this is generic filter -->
      <SearchBar
        :value="searchTerm"
        :searchCallback="searchCallback"
        class="my-1"
      />
      <!-- <button class="border-0">
      <span class="icon-menu-three-dots"></span>
      </button>-->
      <div class="col-2 my-1" v-if="getUserId && isShowFav">
        <CDropdown color="primary" :toggler-text="favouriteTemplate.name">
          <div class="mr-2 ml-2 filter-search" v-if="favouriteFilters.length">
            <CInput
              placeholder="Search"
              name="bookmark-search"
              v-model="searchFilterName"
            >
              <template #prepend-content>
                <CIcon name="cil-magnifying-glass" />
              </template>
            </CInput>
          </div>
          <div class="filter-list">
            <CDropdownItem
              v-for="(favouriteFilter, fIndex) in favouriteFilterList(
                searchFilterName
              )"
              :key="fIndex"
            >
              <!-- v-c-tooltip="{
                content: favouriteFilter.filter_name_date,
              }" -->
              <a
                href="javascript:"
                @click="navigateToBookMarkedUrl(favouriteFilter.url)"
              >
                <label class="px-1 pt-2 mb-0 text-truncate">
                  {{ favouriteFilter.filter_name_date }}
                </label>
              </a>
              <button
                class="border-0 btn-primary"
                v-if="!favouriteFilter.isDefault"
                @click="editBookmark(favouriteFilter)"
              >
                <i class="fas fa-edit" aria-hidden="true"></i>
              </button>
              <button
                class="border-0 btn-primary ml-1"
                v-if="!favouriteFilter.isDefault"
                @click="deleteBookmark(favouriteFilter)"
              >
                <i class="fas fa-trash-alt" aria-hidden="true"></i>
              </button>
            </CDropdownItem>
            <div
              v-if="!favouriteFilterList.length && searchFilterName"
              class="mr-3 ml-3 text-center"
            >
              <h5>No Bookmark Match</h5>
            </div>
            <CDropdownDivider
              v-if="favouriteFilterList.length"
            ></CDropdownDivider>
          </div>
          <CDropdownItem>
            <CButton
              class="btn btn-primary w-100"
              @click="addBookmarkModal()"
              size="sm"
              >Add {{ favouriteTemplate.content }}</CButton
            >
          </CDropdownItem>
        </CDropdown>
      </div>
      <div class="col-2 my-1">
        <CButton
          class="d-flex"
          key="primary"
          color="primary"
          @click="toggleSideBar"
          :disabled="isFetchingFilter"
        >
          Filter
          <CIcon name="cil-filter" size="sm" />
        </CButton>
        <!-- <div
            style="cursor:pointer;display: -webkit-inline-box"
            class="m-0 p-0"
            @click="toggleSideBar"
          >
            <p class="d-inline mb-0 ml-4">Filter</p>
            <button :disabled="isFetchingFilter" class="border-0">
              <i class="fa fa-filter" aria-hidden="true"></i>
            </button>
        </div>-->
      </div>
      <sidebar :show="sideBarShow" :toggleSideBar="toggleSideBar">
        <template #header>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <button
                :disabled="isFetchingFilter"
                @click="toggleSideBar"
                class="btn btn-primary ml-1 mr-2"
              >
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </button>
              <h6 class="m-0">Selected Filters</h6>
            </div>
            <span
              v-if="selectedFilterLength"
              @click="clearAllFilters"
              style="cursor: pointer; white-space: nowrap"
              class="pr-3"
              >Clear All ({{ selectedFilterLength }})</span
            >
          </div>
          <div
            style="min-height: 10px; height: 100px; overflow: auto"
            id="selectedSection"
          >
            <SelectedTags
              :currentPageSchema="currentPageSchema"
              :onlySelectedFilters="onlySelectedFilters"
              :clearCallback="onInput"
            />
          </div>
        </template>
        <CListGroup v-if="!isFetchingFilter">
          <div
            v-for="(value, propertyName, index) in groupedFilterData"
            :key="index"
          >
            <div v-if="value.component">
              <div v-if="basicFilterlength && basicFilterlength === index">
                <a
                  class="btn btn-lg toggle px-1 w-100"
                  @click="toggleAdvanced()"
                >
                  <span name="advancedSearch" class="mr-2"
                    >Advanced Search</span
                  >
                  <CIcon
                    v-if="!isAdvancedFilterVisible"
                    size="lg"
                    name="cil-plus"
                  />
                  <CIcon v-else size="lg" name="cil-minus" />
                </a>
              </div>
              <!--@DO_NOT_REMOVE below commented code
               needed for testing, we replaced this block with AccordionComponent component once geniric filter stable we can remove the code
              -->
              <!-- <div v-show="isBasicFilterShow(index) || isAdvanceFilterShow(index)">
                <CListGroupItem class="py-2 border-1" href="javascript:" @click="showCollapse(index)">
                  <div class="d-flex justify-content-between align-items-center">
                    <p class="m-0">{{ value.label }}</p>
                    <button class="btn shadow-none">
                      <i
                        :class="
                          `fa fa-${collapseIndex === index ? 'minus' : 'plus'}`
                        "
                      ></i>
                    </button>
                  </div>
                </CListGroupItem>
                <CCollapse :show="collapseIndex === index">
                  <CCard class="mx-0 my-0 rounded-0" body-wrapper>
                    <component
                      :is="value.component"
                      :key="index"
                      :name="value.name"
                      :label="''"
                      :placeholder="value.placeholder"
                      :rangeValue="selectedFilters[propertyName][0]"
                      :selectedValue="selectedFilters[propertyName]"
                      :value="selectedFilters[propertyName]"
                      @input="onInput"
                      :onInput="onInput"
                      :options="getDependentOptions(propertyName)"
                      :taggable="value.taggable"
                      :multiple="value.multiple"
                      :clearable="value.clearable"
                      :disabled="isDisable(propertyName)"
                      :fetchOptions="fetchOptions"

                      :groupName="value.name"
                      :groupFilterSchema="value.schema"
                      :selectedFilters="selectedFilters"
                      :getDependentOptions="getDependentOptions"
                      :isDisable="isDisable"
                    />
          
                  </CCard>
                </CCollapse>
              </div> -->
              <AccordionComponent
                :basicFilterlength="basicFilterlength"
                :isAdvancedFilterVisible="isAdvancedFilterVisible"
                :collapseIndex="collapseIndex"
                :index="index"
                :showCollapse="showCollapse"
                :value="value"
                :selectedFilters="selectedFilters"
                :propertyName="propertyName"
                :getDependentOptions="getDependentOptions"
                :isDisable="isDisable"
                :onInput="onInput"
                :fetchOptions="fetchOptions"
                :accordionFetchOption="accordionFetchOption"
                :specialitySmartOptions="specialitySmartOptions"
              />
            </div>
            <div v-else>
              <CListGroupItem
                class="py-2 border-1"
                href="javascript:"
                @click="showCollapse(`${propertyName}${index}`)"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <p class="m-0">{{ propertyName.replace("_", " ") }}</p>
                  <button class="btn shadow-none">
                    <em
                      :class="`fa fa-${
                        collapseIndex === `${propertyName}${index}`
                          ? 'minus'
                          : 'plus'
                      }`"
                    ></em>
                  </button>
                </div>
              </CListGroupItem>
              <CCollapse :show="collapseIndex === `${propertyName}${index}`">
                <CCard class="mx-0 my-0 rounded-0" body-wrapper>
                  <div
                    v-for="(gpvalue, gppropertyName, gpindex) in value"
                    :key="gpindex"
                  >
                    <AccordionComponent
                      :basicFilterlength="basicFilterlength"
                      :isAdvancedFilterVisible="isAdvancedFilterVisible"
                      :collapseIndex="collapseIndex"
                      :index="gpindex"
                      :showCollapse="showCollapse"
                      :value="gpvalue"
                      :selectedFilters="selectedFilters"
                      :propertyName="gppropertyName"
                      :getDependentOptions="getDependentOptions"
                      :isDisable="isDisable"
                      :onInput="onInput"
                      :groupName="propertyName"
                      :fetchOptions="fetchOptions"
                      :accordionFetchOption="accordionFetchOption"
                      :specialitySmartOptions="specialitySmartOptions"
                    />
                  </div>
                </CCard>
              </CCollapse>
            </div>
          </div>
        </CListGroup>

        <!-- <template #footer>
        <h1>Here is your footer</h1>
        </template>-->
      </sidebar>
      <!-- <div id="filters" class="d-inline col-1" v-bind:class="{'show': isActive}"></div> -->
    </div>
    <div class="mb-3">
      <SelectedTags
        :currentPageSchema="currentPageSchema"
        :onlySelectedFilters="onlySelectedFilters"
        :clearCallback="onInput"
      />
    </div>
    <BookmarkFilter
      :modalTitle="bookmarkFilterModal.modalTitle"
      :modalColor="bookmarkFilterModal.modalColor"
      :isShowPopup="bookmarkFilterModal.isShowPopup"
      :isBookmarkEdit="bookmarkFilterModal.isBookmarkEdit"
      :bookmark_id="bookmarkFilterModal.bookmark_id"
      :closeModal="closeModal"
      :filter_name="bookmarkFilterModal.filter_name"
      :urlPath="bookmarkFilterModal.urlPath"
      :page_name="bookmarkFilterModal.page_name"
      v-if="bookmarkFilterModal.isShowPopup"
    />
    <Modal
      :modalTitle="deleteModal.modalTitle"
      :modalColor="deleteModal.modalColor"
      :modalContent="deleteModal.modalContent"
      :isShowPopup="deleteModal.isShowPopup"
      :buttons="deleteModal.buttons"
      :modalCallBack="modalCallBack"
      :closeOnBackdrop="deleteModal.closeOnBackdrop"
      v-if="deleteModal.isShowPopup"
    />
  </div>
</template>

<script>
import Vue from "vue";
import sidebar from "./sidebar";
import SearchBar from "./searchComponent";
import {
  isObject,
  isEmptyObjectCheck,
  Role,
  getDefaultBookmark,
} from "@/helpers/helper";
import SelectedTags from "./selectedTags";
import { mapGetters, mapActions } from "vuex";
import BookmarkFilter from "@/components/reusable/BookmarkFilter";
import Modal from "@/components/reusable/Modal";
import AccordionComponent from "./AccordionComponent.vue";
import moment from "moment";
import _ from "lodash";
Vue.component("sidebar", sidebar);
Vue.component("SearchBar", SearchBar);
Vue.component("SelectedTags", SelectedTags);
Vue.component("BookmarkFilter", BookmarkFilter);
Vue.component("Modal", Modal);
Vue.component("AccordionComponent", AccordionComponent);

export default {
  props: [
    "callback",
    "searchCallback",
    "basicFilterlength",
    "filterSchema",
    "selectedFilters",
    "filterDependencyObj",
    "isFetchingFilter",
    "filterAction",
  ],
  data() {
    return {
      Role,
      isActive: true,
      isAdvancedFilterVisible: false,
      sideBarShow: false,
      collapseIndex: null,
      isAddBookmark: false,
      bookmarkFilterModal: {
        modalColor: "primary",
        modalTitle: "Add Favourite Filter",
        size: "lg",
        isShowPopup: false,
        closeOnBackdrop: false,
        isBookmarkEdit: false,
        bookmark_id: null,
        filter_name: null,
        urlPath: "",
        page_name: "",
      },
      deleteModal: {
        modalTitle: "",
        modalColor: "danger",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Delete"],
        closeOnBackdrop: false,
        filter_bookmark_id: null,
      },
      searchFilterName: "",
      favouriteTemplate: {},
      specialitySmartOptions: [],
    };
  },
  beforeDestroy() {
    this.clearAllSelectedFilters();
  },
  computed: {
    ...mapGetters([
      "currentPageSchema",
      "favouriteFilters",
      "getUserId",
      "getRole",
    ]),
    selectedFilterLength() {
      return Object.keys(this.onlySelectedFilters).length;
    },
    groupedFilterData() {
      let filterObject = {};
      const data = this.filterSchema;
      for (let i in data) {
        let item = data[i];
        let key = i;
        let groupFilterName = data[i]["groupFilterName"];
        if (groupFilterName) {
          if (filterObject[groupFilterName]) {
            filterObject[groupFilterName] = {
              ...filterObject[groupFilterName],
              [key]: item,
            };
          } else {
            filterObject[groupFilterName] = { [key]: item };
          }
        } else {
          filterObject[i] = data[i];
        }
      }

      return filterObject;
    },
    onlySelectedFilters() {
      const onlySelectedData = {};
      const selectedObjectKeys = Object.keys(this.selectedFilters);
      selectedObjectKeys.forEach((element) => {
        let data = this.selectedFilters[element];
        if (element === "searchTerm" && data) {
          if (data?.length)
            onlySelectedData[element] =
              typeof data === "string" ? [data] : data;
        } else if (
          this.currentPageSchema[element] &&
          this.currentPageSchema[element]["component"] === "filterRange" &&
          data.length
        ) {
          onlySelectedData[element] = data.map((val) => ({
            label: val,
            code: val,
          }));
        } else if (Array.isArray(data) && data.length) {
          onlySelectedData[element] = data;
        } else if (isObject(data) && !isEmptyObjectCheck(data)) {
          onlySelectedData[element] = [data];
        }
      });
      return onlySelectedData;
    },
    searchTerm() {
      return this.selectedFilters["searchTerm"] || "";
    },
    isBasicFilterShow() {
      return (index) => {
        if (!this.basicFilterlength) {
          return true;
        }
        return this.basicFilterlength > index;
      };
    },
    isAdvanceFilterShow() {
      return (index) => {
        return (
          this.basicFilterlength &&
          this.basicFilterlength <= index &&
          this.isAdvancedFilterVisible
        );
      };
    },
    isDisable() {
      return (propertyName) => {
        const secondLevelDependentDisable =
          this.filterSchema[propertyName]["secondLevelDependentDisable"];
        const selectInversionDependentDisable =
          this.filterSchema[propertyName]["selectDependentDisable"];
        if (secondLevelDependentDisable && secondLevelDependentDisable.length) {
          let secondLevelDepArray =
            this.filterSchema[propertyName]["secondLevelDependentDisable"];
          const dependsOn =
            this.filterSchema[propertyName]["secondLevelDependentDisable"][0];
          const selectedDependent = this.selectedFilters[dependsOn];
          //Comment #1 -- Disabled candidate level if candidate type is AHP (code=3)... Code starts here
          if (
            propertyName == "candidateLevel" &&
            secondLevelDependentDisable?.some(
              (val) => val == "candidateType"
            ) &&
            (Array.isArray(selectedDependent) || isObject(selectedDependent))
          ) {
            if (
              selectedDependent.code === 3 ||
              selectedDependent[0]?.code === 3
            )
              return true;
          }
          //Comment #1 -- code ends
          if (Array.isArray(selectedDependent) && !selectedDependent.length) {
            return true;
          }
          if (
            isObject(selectedDependent) &&
            isEmptyObjectCheck(selectedDependent)
          ) {
            return true;
          }
        }
        if (
          selectInversionDependentDisable &&
          selectInversionDependentDisable.length
        ) {
          const selectDependentDisable =
            this.filterSchema[propertyName]["selectDependentDisable"];
          let disableCheck = false;
          selectDependentDisable.map((item) => {
            const dependsOn = item;
            const selectedDependent = this.selectedFilters[dependsOn];
            if (Array.isArray(selectedDependent) && selectedDependent.length) {
              disableCheck = true;
            }
            if (
              isObject(selectedDependent) &&
              !isEmptyObjectCheck(selectedDependent)
            ) {
              disableCheck = true;
            }
          });
          return disableCheck;
        }
        return false;
      };
    },
    getDependentOptions() {
      return (propertyName) => {
        if (
          !this.filterDependencyObj ||
          !this.filterDependencyObj[propertyName]
        ) {
          return [];
        }
        if (
          this.filterSchema[propertyName]["component"] === "customSelectOption"
        ) {
          return this.filterDependencyObj[propertyName]["defaultEntries"];
        }
        if (this.filterSchema[propertyName]["dependsOn"].length) {
          const dependentOptions = [];
          let selectedDependentIds = [];
          const dependsOn = this.filterSchema[propertyName]["dependsOn"][0];
          const selectedDependent = this.selectedFilters[dependsOn];
          // Based on schema multiple option false, array will be mutated to object
          // based on array validation
          if (!Array.isArray(selectedDependent)) {
            selectedDependentIds = selectedDependent["code"]
              ? [selectedDependent["code"]]
              : [];
          } else {
            selectedDependentIds = selectedDependent.length
              ? selectedDependent.map((val) => val?.code)
              : [];
          }
          if (selectedDependentIds.length) {
            selectedDependentIds.forEach((id) => {
              const depOptions =
                this.filterDependencyObj[propertyName][dependsOn][id];
              if (depOptions) {
                dependentOptions.push(
                  ...this.filterDependencyObj[propertyName][dependsOn][id]
                );
              }
            });
            return dependentOptions;
          }
          return (
            this.filterDependencyObj[propertyName]?.["defaultEntries"] || []
          );
        } else {
          return (
            this.filterDependencyObj[propertyName]?.["defaultEntries"] || []
          );
        }
      };
    },
    favouriteFilterList() {
      return (searchKey) => {
        let { path } = this.$route;
        const { customerAdmin, systemAdmin } = Role;
        const defaultFilter = getDefaultBookmark(path);
        let favouriteFilters = this.favouriteFilters || [];
        let isDefaultFilterAdded =
          this.favouriteFilters.filter((item) => item.isDefault === true)
            .length === 0;
        if (
          (this.getRole === systemAdmin || this.getRole === customerAdmin) &&
          isObject(defaultFilter) &&
          !isEmptyObjectCheck(defaultFilter) &&
          isDefaultFilterAdded
        ) {
          favouriteFilters.unshift(defaultFilter);
        }
        return favouriteFilters
          .map((val) => {
            const { filter_name, last_modified_on, created_on, isDefault } =
              val;
            let filter_name_date = filter_name;
            if (!isDefault) {
              let filter_date = last_modified_on || created_on;
              filter_date = moment(filter_date).format("DD-MMM-YYYY");
              filter_name_date = `${filter_name} (${filter_date})`;
            }
            return {
              ...val,
              filter_name_date,
            };
          })
          .filter(
            (val) =>
              val.filter_name.toLowerCase().indexOf(searchKey.toLowerCase()) >
              -1
          );
      };
    },
    isShowFav() {
      if (
        this.$router?.currentRoute?.name == "JobBoardByStatus" &&
        [this.Role.customerAdmin, this.Role.customerRecruiter].includes(
          this.getRole
        )
      ) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.selectedAdvancedFilterOpen();
  },
  methods: {
    ...mapActions([
      "clearAllSelectedFilters",
      "dynamicSearchAction",
      "fetchFavouriteFilter",
      "deleteFavourtieFilter",
      "updateSelectedFilterFromFilterCode",
      "initAllFilters",
    ]),
    fetchOptions(search, loading, propertyName) {
      let actionName =
        this.filterSchema[propertyName]["filterDynamicSearchAction"];
      let dependentQuerys = this.filterSchema[propertyName]["dependentQuerys"];
      let { query } = this.$route;
      let dynamicQueryString = "";
      dependentQuerys.forEach((queryItem) => {
        let queryKey = this.filterSchema[queryItem]["queryKey"];
        let queryResult = query[queryItem];
        if (actionName === "genericSpecialitySearch") {
          queryKey = "candidate_type_id";
          queryResult = queryResult[1];
        }
        if (queryKey && queryResult)
          dynamicQueryString += `&${queryKey}=${queryResult}`;
      });
      dynamicQueryString = `query=${search}${dynamicQueryString}`;
      if (actionName) {
        if (actionName == "genericSpecialitySearch" && search.length < 3) {
          return;
        } else {
          this.dynamicSearchAction({
            query: dynamicQueryString,
            loading,
            actionName,
            search,
          });
        }
      }
      return;
    },
    async accordionFetchOption(searchQuery, loading, propertyName, cb) {
      let actionName =
        this.filterSchema[propertyName]["filterDynamicSearchAction"];
      let dependentQuerys = this.filterSchema[propertyName]["dependentQuerys"];
      let { query } = this.$route;
      let dynamicQueryString = "";
      dependentQuerys.forEach((queryItem) => {
        let queryKey = this.filterSchema[queryItem]["queryKey"];
        let queryResult = query[queryItem];
        if (actionName === "genericSpecialitySearch") {
          queryKey = "candidate_type_id";
          queryResult = queryResult[1];
        }
        if (queryKey && queryResult)
          dynamicQueryString += `&${queryKey}=${queryResult}`;
      });
      dynamicQueryString = `query=${encodeURIComponent(
        searchQuery
      )}${dynamicQueryString}`;
      if (actionName) {
        if (actionName == "genericSpecialitySearch" && searchQuery.length < 3) {
          cb(null, []);
        } else {
          const search = `${encodeURIComponent(searchQuery)}`;
          await this.dynamicSearchAction({
            query: dynamicQueryString,
            loading,
            actionName,
            search,
          }).then(async (res) => {
            const options = await this.convertOptionsToTreeOptions(
              this.getDependentOptions("specialitySearch")
            );
            this.specialitySmartOptions = options;
            cb(null, options);
          });
        }
      }
    },
    selectedAdvancedFilterOpen() {
      let { query } = this.$route;
      const queryKeys = Object.keys(query);
      const advancedFilterKeys = Object.keys(this.filterSchema).slice(
        this.basicFilterlength
      );
      if (queryKeys && queryKeys.length) {
        queryKeys.forEach((element) => {
          if (advancedFilterKeys.includes(element)) {
            this.isAdvancedFilterVisible = true;
            return;
          }
        });
      }
    },
    toggleAdvanced() {
      this.isAdvancedFilterVisible = !this.isAdvancedFilterVisible;
    },
    onInput(name, value, type) {
      // value = value || []; // TODO
      if (name === "searchTerm") {
        this.searchCallback({ type: "searchTerm", name, value });
        this.callback({ name, value, type: "searchTerm" });
      } else {
        this.callback({ name, value, type });
      }
    },
    toggleFilter: function () {
      this.isActive = !this.isActive;
    },
    toggleSideBar() {
      this.sideBarShow = !this.sideBarShow;
      this.collapseIndex = null;
    },
    showCollapse(index) {
      if (this.collapseIndex === index) {
        this.collapseIndex = null;
        return;
      }
      this.collapseIndex = index;
    },
    addBookmarkModal() {
      let { fullPath, path } = this.$route;
      this.bookmarkFilterModal = {
        ...this.bookmarkFilterModal,
        modalTitle: `Add ${this.favouriteTemplate.content}`,
        isShowPopup: true,
        isBookmarkEdit: false,
        bookmark_id: null,
        filter_name: null,
        urlPath: fullPath,
        page_name: path,
      };
    },
    closeModal() {
      this.bookmarkFilterModal.isShowPopup = false;
    },
    navigateToBookMarkedUrl(url) {
      this.$router
        .push({
          path: url,
        })
        .then((res) => {
          this.initAllFilters().then(() => {
            this.clearAllSelectedFilters();
            this.updateSelectedFilterFromFilterCode(
              this.$router.currentRoute.query
            );
          });
        });
    },
    editBookmark(data) {
      let { filter_bookmark_id, filter_name, page_name, url } = data;
      this.bookmarkFilterModal = {
        ...this.bookmarkFilterModal,
        modalTitle: `Edit ${this.favouriteTemplate.content}`,
        isShowPopup: true,
        isBookmarkEdit: true,
        bookmark_id: filter_bookmark_id,
        filter_name: filter_name,
        urlPath: url,
        page_name: page_name,
      };
    },
    deleteBookmark(data) {
      let { filter_name, filter_bookmark_id } = data;
      this.deleteModal.modalTitle = "Delete Favourite Filter";
      this.deleteModal.modalContent =
        "Do you want to Delete Favourite Filter - " + filter_name;
      this.deleteModal.isShowPopup = true;
      this.deleteModal.filter_bookmark_id = filter_bookmark_id;
    },
    modalCallBack(action) {
      if (action === "Delete") {
        let { path } = this.$route;
        let payload = {
          filter_bookmark_id: this.deleteModal.filter_bookmark_id,
          page_name: path,
        };
        this.deleteFavourtieFilter(payload);
      }
      this.deleteModal.isShowPopup = false;
    },
    clearAllFilters() {
      this.clearAllSelectedFilters().then((res) => {
        this.callback(this.selectedFilters);
      });
    },
    setFavouriteTemplate() {
      let { fullPath, path } = this.$router.history.current;
      if (
        path == "/jobs-list" &&
        (this.getRole == Role.customerCandidate ||
          this.getRole == Role.systemCandidate)
      ) {
        if (fullPath.search("preferredType") != -1) {
          this.favouriteTemplate =
            fullPath.search("favourite") != -1
              ? { name: "Favourite Jobs", content: "Preferred Filter" }
              : { name: "Applied Jobs", content: "Preferred Filter" };
        } else {
          this.favouriteTemplate = {
            name: "My Preferences",
            content: "Preferred Filter",
          };
        }
      } else {
        this.favouriteTemplate = {
          name: "Favourites",
          content: "Favourite Filter",
        };
      }
    },
    convertOptionsToTreeOptions(options) {
      let r =
        (options &&
          _.reduce(
            options,
            function (result, value, key) {
              result && result.length ? null : (result = []);
              if (value?.group_by) {
                let _groupByindex = _.findIndex(result, {
                  label: value?.group_by,
                });
                let sub_spl_label = value?.sub_speciality;
                let spl_label = value?.speciality;
                const cCode = value?.speciality_reference_group
                  ? value?.speciality_reference_group
                  : value?.code;
                if (_groupByindex < 0) {
                  let opt = {
                    id: `parent-[${cCode}]`,
                    label: value?.group_by,
                    checkBox: false,
                    children: [
                      {
                        id: value?.code,
                        label: spl_label,
                        checkBox: true,
                        spl_label,
                        sub_spl_label,
                      },
                    ],
                    isParent: true,
                  };
                  if (value?.group_by.includes("Sub Speciality")) {
                    opt = {
                      ...opt,
                      children: [
                        {
                          id: `[${value?.code}]`,
                          label: spl_label,
                          spl_label,
                          sub_spl_label,
                          checkBox: true,
                          children: [
                            {
                              id: value?.code,
                              label: sub_spl_label,
                              checkBox: true,
                              spl_label,
                              sub_spl_label,
                            },
                          ],
                        },
                      ],
                    };
                  }
                  result.push(opt);
                } else {
                  if (!result[_groupByindex].id.includes(cCode))
                    result[_groupByindex].id += `,${cCode}`;

                  let _Cindex = _.findIndex(result[_groupByindex].children, {
                    label: spl_label,
                  });
                  if (_Cindex < 0) {
                    let ccData = {
                      id: value?.code,
                      label: spl_label,
                      sub_spl_label,
                      spl_label,
                      checkBox: true,
                    };
                    if (value?.group_by.includes("Sub Speciality")) {
                      ccData = {
                        id: `[${value?.code}]`,
                        label: spl_label,
                        sub_spl_label,
                        spl_label,
                        checkBox: true,
                        children: [
                          {
                            id: value?.code,
                            label: sub_spl_label,
                            sub_spl_label,
                            spl_label,
                            checkBox: true,
                          },
                        ],
                      };
                    }
                    result[_groupByindex].children.push(ccData);
                  } else {
                    if (!result[_groupByindex].children[_Cindex].children) {
                      let _Cvalue = result[_groupByindex].children[_Cindex];
                      let _cId = value?.speciality_reference_group
                        ? value?.speciality_reference_group
                        : _Cvalue.id;
                      result[_groupByindex].children[_Cindex] = {
                        id: _cId,
                        label: spl_label,
                        checkBox: true,
                        children: [
                          {
                            id: _Cvalue.id,
                            label: _Cvalue.sub_spl_label,
                            spl_label: _Cvalue.spl_label,
                            sub_spl_label: _Cvalue.sub_spl_label,
                            checkBox: true,
                          },
                        ],
                      };
                    }
                    result[_groupByindex].children[_Cindex].children.push({
                      id: value?.code,
                      label: sub_spl_label,
                      spl_label,
                      sub_spl_label,
                      checkBox: true,
                    });
                    if (!value?.speciality_reference_group) {
                      result[_groupByindex].children[
                        _Cindex
                      ].id += `,${value.code}`;
                    }
                  }
                }
              }
              return result;
            },
            []
          ).sort((a, b) => {
            return a.label.includes("Main Speciality") ? -1 : 1;
          })) ||
        [];
      return r;
    },
  },
  mounted() {
    if (this.getUserId) {
      const { path } = this.$route;
      this.fetchFavouriteFilter(path);
    }
    this.setFavouriteTemplate();
  },
  //multiple api calls are triggering due to this code block, If anything breaks in existing code, work around inside individual components.
  watch: {
    "$route.query": function (newVal, oldVal) {
      const newQuery = _.reduce(
        newVal,
        function (result, value, key) {
          return _.isEqual(value, oldVal[key])
            ? result
            : value
            ? result.concat(key)
            : result;
        },
        []
      ).filter((val) => val);
      if (newQuery.length === 1 && newQuery.includes("job_uuid")) return;
      if (newQuery.length === 1 && newQuery.includes("specialitySearch")) {
        this.filterAction(true);
      } else {
        this.filterAction();
        this.setFavouriteTemplate();
      }
    },
  },
};
</script>

<style lang="scss">
.generic-filter {
  display: flex;
  padding: 4px 0;
  border: 1px solid #c9cbce;
  color: #085d66e6;
  background-color: #f1f2f5;
  align-items: center;
  justify-content: space-between;
  p {
    font-weight: 600;
    font-size: 18px;
  }
}
.text-color {
  color: #085d66e6;
}
.dropdown-menu .items {
  width: 200px;
}
.dropdown-menu label {
  width: 140px;
}
.filter-list {
  max-height: 250px;
  overflow-y: auto;
}
.filter-search {
  width: 230px;
}
</style>
