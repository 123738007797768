<template>
  <div>
    <CModal
      :title="modalTitle"
      :color="modalColor"
      :show.sync="isShowPopup"
      :closeOnBackdrop="false"
      size="lg"
    >
      <template #header>
        <h5>{{modalTitle}}</h5>
        <button aria-label="Close" class="close" @click="closeModal">x</button>
      </template>
      <template #footer>
        <CButton type="button" color="secondary" class="px-4" @click="closeModal">Cancel</CButton>
        <CButton type="button" color="primary" class="px-4" @click="onSubmit">Submit</CButton>
      </template>
      <ValidationObserver ref="bookmarkfilter" v-slot="{ handleSubmit }">
        <form id="bookmarkfilter" @submit.prevent="handleSubmit(onSubmit)">
          <CRow class="row mb-2">
            <label class="required col-lg-3 col-md-12">Filter Name</label>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <ValidationProvider rules="required" v-slot="{errors}">
                <TextInput
                  name="filter_name"
                  :value="bookmarkfilter.filter_name"
                  @input="handleInput"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </CRow>
          <!-- <CRow class="row mb-2">
            <label class="required col-lg-3 col-md-12">URL</label>
            <div class="col-lg-9 col-md-12 col-sm-12 bookmark-url">
              <p>{{bookMarkURL}}</p>
            </div>
          </CRow> -->
        </form>
      </ValidationObserver>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import TextInput from "@/components/reusable/Fields/TextInput";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import { mapActions } from "vuex";

extend("required", { ...required, message: "This field is required" });

export default {
  name: "BookmarkFilter",
  components: {
    TextInput
  },
  props: [
    "modalTitle",
    "modalColor",
    "isShowPopup",
    "isBookmarkEdit",
    "bookmark_id",
    "closeModal",
    "filter_name",
    "urlPath",
    "page_name"
  ],
  data() {
    return {
      bookmarkfilter: {}
    };
  },
  computed: {
    bookMarkURL() {
      return `${window.location.origin}${this.urlPath}`;
    }
  },
  mounted() {
    if (this.isBookmarkEdit) {
      this.handleInput("filter_name", this.filter_name);
    }
  },
  methods: {
    ...mapActions(["showToast", "addFavouriteFilter", "updateFavouriteFilter"]),
    handleInput(name, value) {
      Vue.set(this.bookmarkfilter, name, value);
    },
    async onSubmit() {
      const isValid = await this.$refs.bookmarkfilter.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!"
        });
        return;
      }
      this.bookmarkfilter = {
        ...this.bookmarkfilter,
        url: this.urlPath,
        page_name: this.page_name
      };
      if (!this.isBookmarkEdit) {
        this.addtoBookmark(this.bookmarkfilter);
      } else {
        this.updateBookmark(this.bookmarkfilter);
      }
    },
    addtoBookmark(data) {
      this.addFavouriteFilter(data);
      this.closeModal();
    },
    updateBookmark(payload) {
      this.updateFavouriteFilter({
        payload,
        filter_bookmark_id: this.bookmark_id
      });
      this.closeModal();
    }
  }
};
</script>

<style scoped>
.bookmark-url {
  word-break: break-all;
}
</style>