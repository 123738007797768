<template>
  <div>
    <div v-if="label" class="mb-2">{{ label }}</div>
    <treeselect
      name="treeSelect"
      :value="treeValue"
      :multiple="multiple"
      :disable-branch-nodes="false"
      :class="{ 'is-danger': error }"
      :clearable="false"
      :placeholder="placeholderCheck"
      :searchPromptText="`Type to Search Speciality or Subspeciality`"
      :default-expand-level="1"
      @input="updateInput"
      :disabled="disabled"
      :async="true"
      :load-options="loadTreeOptions"
      :showCountOf="`ALL_CHILDREN`"
      :loadingText="`Searching...`"
      :clear-on-select="true"
      ref="accordionSelect"
      :defaultOptions="specialitySmartOptions"
      :close-on-select="false"
      :showToolTip="true"
    >
      <div slot="value-label" slot-scope="{ node }">{{ getLabel(node) }}</div>
    </treeselect>
    <small class="has-error" v-if="error">{{ error }}</small>
  </div>
</template>

<script>
const debounce = require("lodash.debounce");
import Treeselect from "@mail2gowthamganesan/techno-vue-treeselect";
import "@mail2gowthamganesan/techno-vue-treeselect/dist/vue-treeselect.css";

import _ from "lodash";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
export default {
  name: "accordionSelect",
  components: { Treeselect },
  props: {
    name: {
      type: String,
      default: "SelectBox",
    },
    value: {
      type: [Object, String, Number, Array],
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: undefined,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    error: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fetchOptions: {
      type: Function,
      default: () => {
        console.log("fetchOptions");
      },
    },
    accordionFetchOption: {
      type: Function,
      default: () => {
        console.log("accordionFetchOptions");
      },
    },
    placeholder: {
      type: String,
      default: "Please start typing",
    },
    enableNA: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    specialitySmartOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    treeValue() {
      const res = this.value.map((v) => v?.id || v?.code) || [];
      return res;
    },
    placeholderCheck() {
      return this.enableNA && this.disabled ? "N/A" : this.placeholder;
    },
  },
  methods: {
    updateInput(value, instanceID) {
      setTimeout(() => {
        this.$nextTick(() => {
          const res = value.map((e) =>
            e.replace(/[[\]]/g, "").replace("parent-", "")
          );
          this.$emit("input", this.name, res);
        });
      }, 0);
    },
    getCount(node) {
      return node?.raw?.count ? `(${node?.raw?.count})` : "";
    },
    getLabel(node) {
      let res = node;
      if (
        this.value.length &&
        !(node.label && !node.label.includes("(unknown)"))
      )
        res = this.value.find(
          (v) => v?.id === node?.id || v?.code === node?.id
        );
      const label = res?.label || "";
      return label.replace(" (unknown)", "");
    },
    loadTreeOptions({ action, searchQuery, callback }) {
      const loading = () => void 0;
      this.accordionFetchOption(
        searchQuery.trim().split(" ")[0],
        loading,
        this.name,
        callback
      );
    },
  },
};
</script>
<style lang="scss">
.has-error {
  color: red;
}
.is-danger {
  .vs__dropdown-toggle {
    border-color: red;
    box-shadow: none;
    outline: none;
  }
}
.vue-treeselect__multi-value-item {
  color: #333;
  background: #f0f0f0;
  font-size: 14px;
}
.vue-treeselect__value-remove {
  color: #333;
}
.vue-treeselect--focused:not(.vue-treeselect--open) .vue-treeselect__control {
  border-color: #0094a8 !important;
  -webkit-box-shadow: 0 0 0 3px #04a9b81a;
  box-shadow: 0 0 0 3px #04a9b81a;
}
</style>
