<template>
  <div>
    <div v-if="label" class="mb-2">{{label}}</div>
    <v-select
      :name="name"
      :value="value"
      @input="input"
      :options="options"
      :taggable="taggable"
      :multiple="multiple"
      :clearable="clearable"
      :disabled="disabled"
      :class="{ 'is-danger': error }"
      @search="onSearch"
      :filterable="false"
    >
      <template slot="no-options">Type to Search Speciality or Subspeciality</template>
      <template slot="option" slot-scope="option">
        <div class="d-flex flex-row">
          <!-- <div class="d-flex align-items-center">{{ 'CA'}}</div> -->
          <div class="d-center d-flex flex-column ml-3">
            <div class="text-justify font-weight-bold">{{ option.speciality }}</div>
            <div class="font-weight-light text-justify">{{option.sub_speciality}}</div>
          </div>
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected d-center">{{ option.label }}</div>
      </template>
    </v-select>
    <small class="has-error" v-if="error">{{error}}</small>
  </div>
</template>

<script>
const debounce = require("lodash.debounce");

export default {
  name: "customSelect",
  props: {
    name: {
      type: String,
      default: "SelectBox"
    },
    value: {
      type: [Object, String, Number, Array],
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: undefined
    },
    taggable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    error: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fetchOptions: {
      type: Function,
      default: () => {
        console.log("fetchOptions");
      }
    }
  },
  methods: {
    input(value) {
      this.$emit("change", this.name, value);
      this.$emit("input", this.name, value);
    },
    handleFetchOption: debounce((loading, search, name, self) => {
      self.fetchOptions(search, loading, name);
    }, 500),
    onSearch(search, loading) {
      this.handleFetchOption(loading, search, this.name, this);
    }
  }
};
</script>
<style lang="scss">
.has-error {
  color: red;
}
.is-danger {
  .vs__dropdown-toggle {
    border-color: red;
    box-shadow: none;
    outline: none;
  }
}
</style>
